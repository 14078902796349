import React, { FunctionComponent } from 'react';
import { StyledTableCell } from './StyledTableCell';

export function TableCellWrapComponent<T>({
  ComponentToWrap,
  wrap,
}: {
  ComponentToWrap: FunctionComponent<T>;
  wrap: boolean;
}) {
  if (wrap) {
    return (props: T) => (
      <StyledTableCell>
        <ComponentToWrap {...props} />
      </StyledTableCell>
    );
  }
  return (props: T) => <ComponentToWrap {...props} />;
}
