import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import { DataListStateType } from '../interfaces/Interfaces';

export const SelectComponent: FunctionComponent<{
  handleFormChange: (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => void;
  value: string | number;
  options: DataListStateType[];
  classes: string;
  label?: string;
  fieldName: string;
}> = ({
  classes,
  handleFormChange,
  value,
  options,
  label,
  fieldName,
}: {
  handleFormChange: (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => void;
  value: string | number;
  options: DataListStateType[];
  classes: string;
  label?: string;
  fieldName: string;
}) => {
  const labelId = fieldName + Math.random;
  return (
    <FormControl className={classes}>
      { label ? <InputLabel htmlFor={labelId}>{label}</InputLabel> : null}
      <Select
        onChange={handleFormChange}
        value={value}
        name={fieldName}
        labelId={labelId}
      >
        {options?.map((i) => (
          <MenuItem value={i.id} key={i.id}>{i.name}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
