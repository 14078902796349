import { Message } from '../components/Copilot';
import {
  type AllDataListsType,
  type FormStateType,
  type ExpensesFormType,
  type IncomeFormType,
  type CombinedCategory,
  type DataListStateType,
  type BroadCategory,
  type NarrowCategory,
  type RecurringExpense,
  type PersonEarner,
  type CopilotResponse,
  CopilotAction,
} from '../interfaces/Interfaces';

async function checkStatus<T>(res: Response, parseMethod: string): Promise<T> {
  if (res.status === 401) {
    throw new Error('Unauthorized');
  }
  if (res.status !== 200) {
    throw new Error('Error! ' + res.status);
  }
  if (parseMethod === 'json') {
    return res.json() as Promise<T>;
  } else if (parseMethod === 'text') {
    return res.text() as unknown as Promise<T>;
  } else {
    return res.blob() as unknown as Promise<T>;
  }
}

const API = {
  cache: {
    categories: [] as CombinedCategory[],
  },
  expenses: async function (
    token: string | null,
    year: string,
    month: string,
  ): Promise<ExpensesFormType[]> {
    return fetch(`/api/expenses/${year}/${month}`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }).then((res) => checkStatus<any[]>(res, 'json'))
    .then(res => res.map(r => ({...r, date: new Date(new Date(r.date).toLocaleString('en-US', {
      timeZone: 'UTC',
    }))})));
  },
  postExpenses: async function (
    token: string | null,
    data: ExpensesFormType
  ): Promise<Response | string> {
    return fetch(`/api/expenses/`, {
      method: 'POST',
      headers: {
        authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }).then((res) => checkStatus<string>(res, 'text'));
  },
  postBatchExpenses: async function (
    token: string | null,
    data: ExpensesFormType[]
  ): Promise<Response | string> {
    return fetch(`/api/expenses/batch`, {
      method: 'POST',
      headers: {
        authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }).then((res) => checkStatus<string>(res, 'text'));
  },
  search: async function (
    token: string | null,
    search: string
  ): Promise<ExpensesFormType[]> {
    return fetch(`/api/expenses/search/${search}`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }).then((res) => checkStatus<any[]>(res, 'json'))
    .then(res => res.map(r => ({...r, date: new Date(new Date(r.date).toLocaleString('en-US', {
      timeZone: 'UTC',
    }))})));
  },
  postIncome: async function (
    token: string | null,
    data: IncomeFormType
  ): Promise<Response | string> {
    return fetch(`/api/income/`, {
      method: 'POST',
      headers: {
        authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }).then((res) => checkStatus<string>(res, 'text'));
  },
  postBatchIncome: async function (
    token: string | null,
    data: IncomeFormType[]
  ): Promise<Response | string> {
    return fetch(`/api/income/batch`, {
      method: 'POST',
      headers: {
        authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }).then((res) => checkStatus<string>(res, 'text'));
  },
  deleteExpenses: async function (
    token: string | null,
    id: number | undefined
  ): Promise<Response | string> {
    return fetch(`/api/expenses/${id}`, {
      method: 'DELETE',
      headers: {
        authorization: `Bearer ${token}`,
      },
    }).then((res) => checkStatus<string>(res, 'text'));
  },
  updateExpenses: async function (
    token: string | null,
    data: ExpensesFormType
  ): Promise<Response | string> {
    return fetch(`/api/expenses/${data.id}`, {
      method: 'PUT',
      headers: {
        authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }).then((res) => checkStatus<string>(res, 'text'));
  },
  getRecurringExpenses: async function (
    token: string | null
  ): Promise<RecurringExpense[]> {
    return fetch(`/api/expenses/recurring`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }).then((res) => checkStatus<RecurringExpense[]>(res, 'json'));
  },
  UpdateRecurringExpense: async function (
    token: string | null,
    data: RecurringExpense
  ): Promise<Response | string> {
    return fetch(`/api/expenses/recurring/${data.id}`, {
      method: 'PUT',
      headers: {
        authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }).then((res) => checkStatus<string>(res, 'text'));
  },
  addRecurringExpense: async function (
    token: string | null,
    data: RecurringExpense
  ): Promise<Response | string> {
    return fetch(`/api/expenses/recurring`, {
      method: 'POST',
      headers: {
        authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }).then((res) => checkStatus<string>(res, 'text'));
  },
  deleteRecurringExpense: async function (
    token: string | null,
    id: number | undefined
  ): Promise<Response | string> {
    return fetch(`/api/expenses/recurring/${id}`, {
      method: 'DELETE',
      headers: {
        authorization: `Bearer ${token}`,
      },
    }).then((res) => checkStatus<string>(res, 'text'));
  },
  income: async function (
    token: string | null,
    year: string,
    month: string,
    ): Promise<IncomeFormType[]> {
    return fetch(`/api/income/${year}/${month}`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }).then((res) => checkStatus<any[]>(res, 'json'))
    .then(res => res.map(r => ({...r, date: new Date(new Date(r.date).toLocaleString('en-US', {
      timeZone: 'UTC',
    }))})));
  },
  updateIncome: async function (
    token: string | null,
    data: IncomeFormType
  ): Promise<Response | string> {
    return fetch(`/api/income/${data.id}`, {
      method: 'PUT',
      headers: {
        authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }).then((res) => checkStatus<string>(res, 'text'));
  },
  deleteIncome: async function (
    token: string | null,
    id: number | undefined
  ): Promise<Response | string> {
    return fetch(`/api/income/${id}`, {
      method: 'DELETE',
      headers: {
        authorization: `Bearer ${token}`,
      },
    }).then((res) => checkStatus<string>(res, 'text'));
  },
  pivot: async function (
    token: string | null,
    yearMonthObj: FormStateType
  ): Promise<ExpensesFormType[]> {
    return fetch(`/api/expenses/${yearMonthObj.year}/${yearMonthObj.month}`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }).then((res) => checkStatus<ExpensesFormType[]>(res, 'json'));
  },
  dataList: async function (token: string | null): Promise<AllDataListsType> {
    return fetch(`/api/datalists`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }).then((res) => checkStatus<AllDataListsType>(res, 'json'));
  },
  categories: async function (
    token: string | null
  ): Promise<CombinedCategory[]> {
    return fetch(`/api/categories/combined`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
      .then((res) => checkStatus<CombinedCategory[]>(res, 'json'))
      .then((res) => {
        if (!this.cache.categories.length) {
          this.cache.categories = res;
        }
        return this.cache.categories;
      });
  },
  broadCategories: async function (
    token: string | null
  ): Promise<BroadCategory[]> {
    return fetch(`/api/categories/broad`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }).then((res) => checkStatus<BroadCategory[]>(res, 'json'));
  },
  UpdateBroadCategory: async function (
    token: string | null,
    data: BroadCategory
  ): Promise<Response | string> {
    return fetch(`/api/categories/broad/${data.id}`, {
      method: 'PUT',
      headers: {
        authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }).then((res) => checkStatus<string>(res, 'text'));
  },
  addBroadCategory: async function (
    token: string | null,
    data: BroadCategory
  ): Promise<Response | string> {
    return fetch(`/api/categories/broad`, {
      method: 'POST',
      headers: {
        authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }).then((res) => checkStatus<string>(res, 'text'));
  },
  deleteBroadCategory: async function (
    token: string | null,
    id: number | undefined
  ): Promise<Response | string> {
    return fetch(`/api/categories/broad/${id}`, {
      method: 'DELETE',
      headers: {
        authorization: `Bearer ${token}`,
      },
    }).then((res) => checkStatus<string>(res, 'text'));
  },
  narrowCategories: async function (
    token: string | null
  ): Promise<NarrowCategory[]> {
    return fetch(`/api/categories/narrow`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }).then((res) => checkStatus<NarrowCategory[]>(res, 'json'));
  },
  UpdateNarrowCategory: async function (
    token: string | null,
    data: NarrowCategory
  ): Promise<Response | string> {
    return fetch(`/api/categories/narrow/${data.id}`, {
      method: 'PUT',
      headers: {
        authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }).then((res) => checkStatus<string>(res, 'text'));
  },
  addNarrowCategory: async function (
    token: string | null,
    data: NarrowCategory
  ): Promise<Response | string> {
    return fetch(`/api/categories/narrow`, {
      method: 'POST',
      headers: {
        authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }).then((res) => checkStatus<string>(res, 'text'));
  },
  deleteNarrowCategory: async function (
    token: string | null,
    id: number | undefined
  ): Promise<Response | string> {
    return fetch(`/api/categories/narrow/${id}`, {
      method: 'DELETE',
      headers: {
        authorization: `Bearer ${token}`,
      },
    }).then((res) => checkStatus<string>(res, 'text'));
  },
  persons: async function (token: string | null): Promise<DataListStateType[]> {
    return fetch(`/api/categories/persons`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }).then((res) => checkStatus<DataListStateType[]>(res, 'json'));
  },
  earners: async function (token: string | null): Promise<DataListStateType[]> {
    return fetch(`/api/categories/earners`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }).then((res) => checkStatus<DataListStateType[]>(res, 'json'));
  },
  personEarners: async function (
    token: string | null
  ): Promise<PersonEarner[]> {
    return fetch(`/api/categories/person_earners`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }).then((res) => checkStatus<PersonEarner[]>(res, 'json'));
  },
  UpdatePersonEarners: async function (
    token: string | null,
    data: PersonEarner
  ): Promise<Response | string> {
    return fetch(`/api/categories/person_earners/${data.id}`, {
      method: 'PUT',
      headers: {
        authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }).then((res) => checkStatus<string>(res, 'text'));
  },
  addPersonEarner: async function (
    token: string | null,
    data: PersonEarner
  ): Promise<Response | string> {
    return fetch(`/api/categories/person_earners`, {
      method: 'POST',
      headers: {
        authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }).then((res) => checkStatus<string>(res, 'text'));
  },
  deletePersonEarner: async function (
    token: string | null,
    id: number | undefined
  ): Promise<Response | string> {
    return fetch(`/api/categories/person_earner/${id}`, {
      method: 'DELETE',
      headers: {
        authorization: `Bearer ${token}`,
      },
    }).then((res) => checkStatus<string>(res, 'text'));
  },
  login: async function (data: {
    username: string;
    password: string;
  }): Promise<{ token: string }> {
    return fetch('/auth/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }).then((res) => checkStatus<{ token: string }>(res, 'json'));
  },
  checkAuth: async function (
    token: string | null
  ): Promise<{ username: string; token: string }> {
    return fetch(`/auth/checkAuth`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }).then((res) =>
      checkStatus<{ username: string; token: string }>(res, 'json')
    );
  },
  downloadFile: function (
    token: string | null,
    filename: string,
    start: string,
    end: string
  ): void {
    fetch(`/api/expenses/file/${start}/${end}`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
      .then((res) => checkStatus<Blob>(res, 'blob'))
      .then((blob) => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.download = filename;
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove(); //afterwards we remove the element again
      });
  },
  copilot: async function (
    messages: Message[],
    token: string | null
  ): Promise<Response> {
    return fetch(`/api/ai/copilot`, {
      method: 'POST',
      headers: {
        authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ messages }),
    })
  }
};

export default API;
