import React, { useState, useEffect, useContext, ChangeEvent } from 'react';
import {
  AllDataListsType,
  DataListStateType,
  CombinedCategory,
  IncomeFormType,
} from '../interfaces/Interfaces';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import {
  IconButton,
  TableRow,
  TextField,
  InputAdornment,
} from '@material-ui/core';
import { withStyles, createStyles, Theme } from '@material-ui/core/styles';
import { blueGrey } from '@material-ui/core/colors';
import { CustomDatePickerComponent } from './CustomDatePickerComponent';
import { useStyles } from '../pages/Home';
import { SelectComponent } from './SelectComponent';
import { StyledTableCell } from './StyledTableCell';
import API from '../utils/API';
import { AuthContext } from '../App';
import { Autocomplete } from '@material-ui/lab';

export const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.background.default,
      },
      '&:nth-of-type(even)': {
        backgroundColor: blueGrey[800],
      },
      '& input': {
        backgroundColor: 'inherit',
        paddingLeft: 0,
      },
    },
  })
)(TableRow);

export interface InputRow {
  entry: IncomeFormType;
  categories: CombinedCategory[];
  persons: DataListStateType[];
  i: number;
  dataLists: AllDataListsType;
  deleteEntry: Function;
}


export default function IncomeInputRow(props: InputRow) {
  const initialFormState: IncomeFormType = {
    id: NaN,
    date: null,
    amount: NaN,
    person_id: NaN,
    source: ''
  };
  const { Auth, setAlertState } = useContext(AuthContext);

  const [state, setState] = useState<IncomeFormType>(initialFormState);

  const classes = useStyles();

  function handleInputRowChange(
    event: React.ChangeEvent<
      HTMLInputElement | { name?: string; value: unknown }
    >
  ): void {
    let name = event.target.name as keyof typeof state;
    setState({ ...state, [name]: event.target.value });
  }

  function handleDateChange(date: Date | null) {
    setState({ ...state, date: date });
  }

  function handleSourceChange(_: ChangeEvent<{}>, value: unknown) {
    if (!value) return;
    setState({ ...state, source: (value as DataListStateType).name, source_id: (value as DataListStateType).id })
  }

  async function saveChanges() {
    let updatedState: any = { ...state };
    updatedState.date = updatedState.date?.toLocaleDateString('en-US');
    API.updateIncome(Auth.token, updatedState);
    try {
      await API.updateIncome(Auth.token, updatedState);
      setAlertState({
        severity: 'success',
        message: 'Record updated!',
        open: true,
      });
    } catch (err) {
      console.log(err);
      setAlertState({
        severity: 'error',
        message: 'Error: Failed to save!',
        open: true,
      });
    }
  }

  useEffect(() => {
    setState(props.entry);
  }, [props.entry]);

  return (
    <StyledTableRow>
      <StyledTableCell>
        <CustomDatePickerComponent
          value={state.date}
          handleChange={handleDateChange}
          tableMode={true}
          />
      </StyledTableCell>
      <StyledTableCell>
      <Autocomplete
            disableClearable={true}
            value={{ name: state.source, id: state.source_id }}
            onChange={handleSourceChange}
            style={{ width: '80%', paddingLeft: '5px' }}
            options={props.dataLists.source.filter(v => !!v.name)}
            getOptionLabel={(v: { name: string }) => v.name || ''}
            getOptionSelected={(o: { id: string }, v: { id: string }) => o.id === v.id}
            renderInput={(params) => <TextField {...params} />}
          />
      </StyledTableCell>
      <StyledTableCell>
        <TextField
          name="amount"
          value={state.amount || ''}
          onChange={handleInputRowChange}
          className="tableInput"
          inputProps={{
            type: 'number',
            step: 0.01,
          }}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
            disableUnderline: true,
          }}
          style={{ width: '80%' }}
            />
      </StyledTableCell>
      <StyledTableCell>
        <SelectComponent
          classes={classes.formControl}
          handleFormChange={handleInputRowChange}
          value={state.person_id || ''}
          options={props.dataLists.person_earner}
          fieldName="person_id"
        />
      </StyledTableCell>
      <StyledTableCell>
        <IconButton color="primary" onClick={saveChanges}>
          <SaveIcon />
        </IconButton>
      </StyledTableCell>
      <StyledTableCell>
        <IconButton
          aria-label="delete"
          color="secondary"
          onClick={() => props.deleteEntry(state.id)}
        >
          <DeleteIcon />
        </IconButton>
      </StyledTableCell>
    </StyledTableRow>
  );
}
