import React, { useState, useEffect } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableRow,
  TableHead,
} from '@material-ui/core';
import { withStyles, createStyles, Theme } from '@material-ui/core/styles';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { ExpensesFormType } from '../interfaces/Interfaces';
import { blueGrey } from '@material-ui/core/colors';
import { useSearchParams } from 'react-router-dom';
import API from '../utils/API';
import { AuthContext } from '../App';

interface PivotState {
  [bCat: string]: {
    total: number;
    transactions: ExpensesFormType[];
    narrow_categories: {
      [nCat: string]: {
        total: number;
        transactions: ExpensesFormType[];
        persons: {
          [person: string]: {
            total: number;
            transactions: ExpensesFormType[];
          };
        };
      };
    };
  };
}

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      padding: 10,
      fontSize: 16,
    },
    body: {
      padding: 10,
      fontSize: 14,
    },
  })
)(TableCell);

const DarkTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.default,
      cursor: 'pointer',
    },
  })
)(TableRow);

const LightTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: blueGrey[800],
      cursor: 'pointer',
    },
  })
)(TableRow);

const ExtraLightTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: blueGrey[700],
    },
  })
)(TableRow);

export default function PivotTable() {
  const [show, setShow] = useState<{ show: boolean; nCats: boolean[] }[]>([
    { show: false, nCats: [false] },
  ]);
  const [params] = useSearchParams();
  const [pivotState, setPivotState] = useState<PivotState>({});
  const { Auth } = React.useContext(AuthContext);

  function toggleShowBCatRow(i: number) {
    let newShow = [...show];
    newShow[i] = { ...newShow[i], show: !newShow[i].show };
    setShow(newShow);
  }

  function toggleShowNCatRow(bI: number, nI: number) {
    let newShow = [...show];
    const nCats = [...newShow[bI].nCats];
    nCats[nI] = !nCats[nI];
    newShow[bI] = { show: newShow[bI].show, nCats };
    setShow(newShow);
  }

  function makePivot(acc: PivotState, curr: ExpensesFormType) {
    if (curr.broad_category) {
      if (acc[curr.broad_category]) {
        acc[curr.broad_category].total += Number(curr.amount);
        acc[curr.broad_category].transactions.push(curr);
      } else {
        acc[curr.broad_category] = {
          total: Number(curr.amount),
          narrow_categories: {},
          transactions: [curr],
        };
      }
      if (curr.narrow_category) {
        if (acc[curr.broad_category].narrow_categories[curr.narrow_category]) {
          acc[curr.broad_category].narrow_categories[curr.narrow_category].total +=
            Number(curr.amount);
          acc[curr.broad_category].narrow_categories[
            curr.narrow_category
          ].transactions.push(curr);
        } else {
          acc[curr.broad_category].narrow_categories[curr.narrow_category] = {
            total: Number(curr.amount),
            persons: {},
            transactions: [curr],
          };
        }
        if (curr.person) {
          if (
            acc[curr.broad_category].narrow_categories[curr.narrow_category]
              .persons[curr.person]
          ) {
            acc[curr.broad_category].narrow_categories[
              curr.narrow_category
            ].persons[curr.person].total += Number(curr.amount);
            acc[curr.broad_category].narrow_categories[
              curr.narrow_category
            ].persons[curr.person].transactions.push(curr);
          } else {
            acc[curr.broad_category].narrow_categories[
              curr.narrow_category
            ].persons[curr.person] = {
              total: Number(curr.amount),
              transactions: [curr],
            };
          }
        }
      } else {
        if (acc[curr.broad_category].narrow_categories['--']) {
          acc[curr.broad_category].narrow_categories['--'].transactions.push(curr);
          acc[curr.broad_category].narrow_categories['--'].total += Number(
            curr.amount
          );
        } else {
          acc[curr.broad_category].narrow_categories = {
            ...acc[curr.broad_category].narrow_categories,
            '--': {
              total: Number(curr.amount),
              transactions: [curr],
              persons: {},
            },
          };
        }
        if (curr.person) {
          if (
            acc[curr.broad_category].narrow_categories['--']?.persons[curr.person]
          ) {
            acc[curr.broad_category].narrow_categories['--'].persons[
              curr.person
            ].total += Number(curr.amount);
            acc[curr.broad_category].narrow_categories['--'].persons[
              curr.person
            ].transactions.push(curr)
          } else {
            acc[curr.broad_category].narrow_categories['--'].persons[curr.person] =
              { total: Number(curr.amount), transactions: [curr] };
          }
        }
      }
    }
    return acc;
  }

  useEffect(() => {
    const year = params.get('year');
    const month = params.get('month');
    if (!year || !month) return;
    API.expenses(Auth.token, year, month).then((rows) => {
      const pivotRows = rows.reduce(makePivot, {} as any)
      setPivotState(pivotRows)
    });
  }, [window.location.search]);

  useEffect(() => {
    let showState = Object.keys(pivotState).map((i: any) => ({
      show: false,
      nCats: Object.keys(pivotState[i]).map(() => false),
    }));
    setShow(showState);
  }, [pivotState]);
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell>Broad category</StyledTableCell>
            <StyledTableCell>Narrow Category</StyledTableCell>
            <StyledTableCell>Person</StyledTableCell>
            <StyledTableCell>Amount</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(pivotState).map((bCat, i) => (
            <>
              <DarkTableRow key={i} onClick={() => toggleShowBCatRow(i)}>
                <StyledTableCell>
                  {bCat}
                  {show[i]?.show ? (
                    <ExpandLess style={{ marginBottom: '-7px' }} />
                  ) : (
                    <ExpandMore style={{ marginBottom: '-7px' }} />
                  )}
                </StyledTableCell>
                <StyledTableCell>{/* Empty Cell */}</StyledTableCell>
                <StyledTableCell>{/* Empty Cell */}</StyledTableCell>
                <StyledTableCell>
                  ${pivotState[bCat].total.toFixed(2)}
                </StyledTableCell>
              </DarkTableRow>
              {pivotState[bCat].narrow_categories && show[i]?.show
                ? Object.keys(pivotState[bCat].narrow_categories).map(
                    (nCat, j) => (
                      <>
                        <LightTableRow
                          key={"n"+i+j}
                          onClick={() => toggleShowNCatRow(i, j)}
                        >
                          <StyledTableCell>{/* Empty Cell */}</StyledTableCell>
                          <StyledTableCell>
                            {nCat}
                            {show[i]?.nCats?.[j] ? (
                              <ExpandLess style={{ marginBottom: '-7px' }} />
                            ) : (
                              <ExpandMore style={{ marginBottom: '-7px' }} />
                            )}
                          </StyledTableCell>
                          <StyledTableCell>{/* Empty Cell */}</StyledTableCell>
                          <StyledTableCell>
                            $
                            {pivotState[bCat].narrow_categories[
                              nCat
                            ].total.toFixed(2)}
                          </StyledTableCell>
                        </LightTableRow>
                        {show[i]?.nCats?.[j]
                          ? pivotState[bCat].narrow_categories[
                              nCat
                            ].transactions?.map(
                              (transaction: ExpensesFormType, k) => (
                                <ExtraLightTableRow key={"e"+k+j}>
                                  <StyledTableCell>
                                    {/* Empty Cell */}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {transaction.vendor}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {transaction.person}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    ${transaction.amount.toFixed(2)}
                                  </StyledTableCell>
                                </ExtraLightTableRow>
                              )
                            )
                          : null}

                        {pivotState[bCat].narrow_categories[nCat].persons
                          ? Object.keys(
                              pivotState[bCat].narrow_categories[nCat].persons
                            ).map((person: string, i: number) => (
                              <ExtraLightTableRow key={"p"+i}>
                                <StyledTableCell></StyledTableCell>
                                <StyledTableCell></StyledTableCell>
                                <StyledTableCell>{person}</StyledTableCell>
                                <StyledTableCell>
                                  $
                                  {pivotState[bCat].narrow_categories[
                                    nCat
                                  ].persons[person].total.toFixed(2)}
                                </StyledTableCell>
                              </ExtraLightTableRow>
                            ))
                          : null}
                      </>
                    )
                  )
                : null}
            </>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
