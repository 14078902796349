import React, { useEffect, useState } from 'react';
import {
  CombinedCategory,
  DataListStateType,
  ExpensesFormType,
} from '../interfaces/Interfaces';
import { SelectComponent } from './SelectComponent';
import { StyledTableCell } from './StyledTableCell';
import { TableCellWrapComponent } from './TableCellWrapComponent';

type ReactInputEvent = React.ChangeEvent<{ name?: string; value: unknown }>;

export function CategorySelectGroupComponent({
  classes,
  formState,
  handleFormChange,
  categories,
  persons,
  tableMode = false,
}: {
  classes: string;
  formState: ExpensesFormType;
  handleFormChange: (e: ReactInputEvent) => void;
  categories: CombinedCategory[];
  persons: DataListStateType[];
  tableMode?: boolean;
}) {
  const [currentCategory, setCurrentCategory] = useState<CombinedCategory>({
    name: "",
    id: NaN,
    narrowCategories: []
  });

  function updateCurrentCategory(event: ReactInputEvent): void {
    let category = categories.filter((i) => i.id === event.target.value)[0];
    setCurrentCategory(category);
  }

  const TableCellSelectComponent = TableCellWrapComponent<
    Parameters<typeof SelectComponent>[0]
  >({
    wrap: tableMode,
    ComponentToWrap: SelectComponent,
  });

  useEffect(() => {
    const category = categories.find(cat => cat.id === formState.broad_category_id)
    if (!category) return;
    setCurrentCategory({
      name: formState.broad_category,
      id: formState.broad_category_id,
      narrowCategories: category.narrowCategories,
      hasPerson: category.hasPerson,
    })
  }, [categories, formState, persons])

  return (
    <>
      <TableCellSelectComponent
        classes={""}
        handleFormChange={(e) => {
          handleFormChange(e);
          updateCurrentCategory(e);
        }}
        value={formState.broad_category_id}
        options={categories}
        label={tableMode ? undefined : "Broad Category"}
        fieldName="broad_category_id"
        />
      {formState.broad_category_id &&
      currentCategory.narrowCategories?.length ? (
        <TableCellSelectComponent
          classes={""}
          handleFormChange={handleFormChange}
          value={formState.narrow_category_id}
          options={currentCategory.narrowCategories}
          label={tableMode ? undefined : "Narrow Category"}
          fieldName="narrow_category_id"
        />
      ) : tableMode && <StyledTableCell/>}
      {currentCategory.hasPerson ? (
        <TableCellSelectComponent
          classes={""}
          handleFormChange={handleFormChange}
          value={formState.person_id}
          options={persons}
          label={tableMode ? undefined : "Person"}
          fieldName="person_id"
        />
      ) : tableMode && <StyledTableCell/>}
    </>
  );
}
