import React, { useContext, useEffect, useState } from 'react';
import {
  makeStyles,
  Theme,
  createStyles,
} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MoreIcon from '@material-ui/icons/MoreVert';
import { AuthContext } from '../App';
import { emptyDatabase } from '../utils/db';
import API from '../utils/API';
import { Dialog, DialogContent } from '@material-ui/core';
import { CustomDatePickerComponent } from './CustomDatePickerComponent';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grow: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(1),
    },
    title: {
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
    },
    sectionDesktop: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
    },
    sectionMobile: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
  })
);

export default function MyAppBar({ showDownload, linkLocation }: { showDownload: boolean, linkLocation: string }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [endDate, setEndDate] = useState<Date | null>(new Date());

  const isMenuOpen = Boolean(anchorEl);
  const { Auth, setAuth } = useContext(AuthContext);

  function openDownloadDialog() {
    setDialogOpen(true)
  }

  function handleDownloadDialogClose() {
    setDialogOpen(false)
  }

  function downloadFile(): void {
    API.downloadFile(
      Auth.token,
      `${Auth.user}_expenses.xlsx`,
      startDate!.toLocaleDateString("en-US").replaceAll("/", "-"),
      endDate!.toLocaleDateString("en-US").replaceAll("/", "-")
    );
    handleMenuClose();
  }

  function logout(): void {
    setAuth({ type: 'LOGOUT' });
    emptyDatabase();
  }

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const menuId = 'mobile-menu-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={openDownloadDialog}>Downloads</MenuItem>
      <MenuItem onClick={logout}>Logout</MenuItem>
    </Menu>
  );

  return (
    <div className={classes.grow}>
      <AppBar position="static">
        <Toolbar>
          <Typography className={classes.title} variant="h6" noWrap>
            Finances
          </Typography>
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <Link style={{ color: "white", textDecoration: "none" }} to={`/${linkLocation}`}>
              <Button color="inherit">
                {linkLocation}
              </Button>
            </Link>
            {showDownload ?
              <Button color="inherit" onClick={openDownloadDialog}>
                Download
              </Button>
            : null}
            <Button color="secondary" variant="contained" onClick={logout}>
              Logout
            </Button>
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMenu}
      <Dialog open={dialogOpen} onClose={handleDownloadDialogClose}>
        <DialogContent>
          <Typography variant="h5" component="h5">
            Download All Data
          </Typography>
          <Typography>
            Select Date Range:
          </Typography>
          <CustomDatePickerComponent value={startDate} handleChange={setStartDate}/>
          <CustomDatePickerComponent value={endDate} handleChange={setEndDate}/>
          <Button color="primary" variant="contained" onClick={downloadFile} style={{marginLeft: '79%'}}>Download</Button>
        </DialogContent>
      </Dialog>

    </div>
  );
}
