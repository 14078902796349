import React, { useEffect, useState, useReducer } from 'react';
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from 'react-router-dom';
import Home from './pages/Home';
import Login from './pages/Login';
import API from './utils/API';
import { testDatabase } from './utils/db';
import './App.css';
import CustomizedSnackbar from './components/SnackBar';
import { AlertStateType, AllDataListsType, Auth, ContextState } from './interfaces/Interfaces';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import { green, blueGrey, red } from '@material-ui/core/colors';

import { MuiPickersOverrides } from '@material-ui/pickers/typings/overrides';
import { CssBaseline, ThemeOptions } from '@material-ui/core';
import { Edit } from './pages/Edit';
import ExpenseTable from './components/ExpenseTable';
import IncomeTable from './components/IncomeTable';
import PivotTable from './components/PivotTable';

// Types to allow for theme customization
type overridesNameToClassKey = {
  [P in keyof MuiPickersOverrides]: keyof MuiPickersOverrides[P];
};

type CustomType = {
  MuiPickersBasePicker: {
    pickerView: {
      maxWidth?: string;
    };
  };
};

declare module '@material-ui/core/styles/overrides' {
  interface ComponentNameToClassKey extends overridesNameToClassKey {}
  export interface ComponentNameToClassKey extends CustomType {}
}

// Instantiating default theme to use breakpoints
const defaultTheme = createTheme();

// Theme factory
function createMyTheme(options: ThemeOptions) {
  return createTheme({
    overrides: {
      MuiPickersBasePicker: {
        container: {
          backgroundColor: blueGrey[900],
          [defaultTheme.breakpoints.down('xs')]: {
            marginLeft: '-10px',
          },
        },
      },
      MuiCard: {
        root: {
          backgroundColor: blueGrey[900],
        },
      },
      MuiDialogContent: {
        root: {
          backgroundColor: blueGrey[900],
        },
      },
      MuiPickersCalendarHeader: {
        iconButton: {
          backgroundColor: blueGrey[900],
        },
      },
    },
    ...options,
  });
}

export const theme = createMyTheme({
  palette: {
    type: 'dark',
    background: {
      default: blueGrey[900],
    },
    primary: {
      main: green[900],
    },
    secondary: {
      main: red[900],
    },
  },
});

function RequireAuth({ children, loggedIn }: React.PropsWithChildren<{ loggedIn: boolean }>)  {
  return loggedIn ? <>{children}</> : <Navigate to="/login" state={{ referrer: window.location.pathname + window.location.search }}/>
}

export const AuthContext = React.createContext<ContextState>({
  Auth: {
    loggedIn: null,
    user: '',
    token: '',
  },
  setAuth: (): void => {},
  setAlertState: (): void => {},
  datalists: {
    source: [],
    person_earner: [],
    narrow_category: [],
    broad_category: [],
    vendor: [],
  },
  setDatalists: (): void => {},
});

export default function App() {
  const reducer = (
    state: Auth,
    action: { type: string; payload?: { user: string; token: string } }
  ): Auth => {
    if (action.type === 'LOGIN' && action.payload) {
      localStorage.setItem('user', action.payload.user);
      localStorage.setItem('token', action.payload.token);
      return {
        ...state,
        loggedIn: true,
        user: action.payload.user,
        token: action.payload.token,
      };
    } else if (action.type === 'LOGOUT') {
      localStorage.clear();
      return {
        ...state,
        loggedIn: false,
        user: '',
        token: '',
      };
    } else {
      return state;
    }
  };

  const [Auth, setAuth] = useReducer(reducer, {
    loggedIn: null,
    user: localStorage.getItem('token') || '',
    token: localStorage.getItem('user') || '',
  });

  const [alertState, setAlertState] = useState<AlertStateType>({
    severity: undefined,
    message: '',
    open: false,
  });

  // State for datalists
  const [datalists, setDatalists] = useState<AllDataListsType>({
    source: [],
    person_earner: [],
    narrow_category: [],
    broad_category: [],
    vendor: [],
  });

  useEffect(() => {
    const token = localStorage.getItem('token');
    const user = localStorage.getItem('user');
    if (token) {
      API.checkAuth(token)
        .then((res) =>
          setAuth({
            type: 'LOGIN',
            payload: { user: res.username, token: token },
          })
        )
        .catch((err) => {
          if (err.message === 'Unauthorized') {
            setAuth({ type: 'LOGOUT' });
          } else {
            setAuth({
              type: 'LOGIN',
              payload: { user: user || '', token: token || '' },
            });
          }
        });
    }
  }, []);

  useEffect(() => {
    testDatabase();
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {/* {Auth.loggedIn !== null
        ?
          <Backdrop open={true}>
            <CircularProgress disableShrink color="inherit" />
            <Typography variant="h1" component="h3">Loading...</Typography>
          </Backdrop>
        : */}
          <AuthContext.Provider value={{ Auth, setAuth, setAlertState, datalists, setDatalists }}>
            <Router>
              <Routes>
                <Route
                  path="home"
                  element={
                    <RequireAuth loggedIn={Auth.loggedIn}>
                      <Home />
                    </RequireAuth>
                  }>
                  <Route path="expenses" element={<ExpenseTable/>}>
        
                  </Route>
                  <Route path="income" element={<IncomeTable/>}>
                  </Route>
                  <Route path="pivot" element={<PivotTable/>}>
                  </Route>
                  <Route path="search" element={<ExpenseTable/>}>
                  </Route>
                  <Route path="" element={<Navigate to="expenses"/>}/>
                </Route>
                <Route
                  path="/edit"
                  element={
                    <RequireAuth loggedIn={Auth.loggedIn}>
                      <Edit />
                    </RequireAuth>
                  }
                  />
                <Route path="/login" element={<Login />}/>
                <Route path="/" element={<Navigate to="/home"/>}/>
              </Routes>
            </Router>
            <CustomizedSnackbar state={alertState} setState={setAlertState} />
          </AuthContext.Provider>
      {/* } */}
    </ThemeProvider>
  );
}
