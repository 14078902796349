import React, {
  useEffect,
  createRef,
  useState,
} from 'react';
import { CombinedCategory, DataListStateType, ExpensesFormType } from '../interfaces/Interfaces';
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { StyledTableRow } from './InputRow';

import {
  makeStyles,
  createStyles,
  Theme,
} from '@material-ui/core/styles';
import ExpenseInputRow from './ExpenseInputRow';
import API from '../utils/API';
import { AuthContext } from '../App';
import { StyledTableCell } from './StyledTableCell';
import { useSearchParams } from 'react-router-dom';
import { Typography } from '@material-ui/core';


export default function ExpenseTable() {

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      table: {
        minWidth: 650,
      },
    })
  );
  const classes = useStyles();

  const columns: { name: string, prop: keyof ExpensesFormType }[] = [
    { name: 'Date', prop: 'date'},
    { name: 'Vendor', prop: 'vendor'},
    { name: 'Amount', prop: 'amount'},
    { name: 'Broad Category', prop: 'broad_category_id'},
    { name: 'Narrow Category', prop: 'narrow_category_id'},
    { name: 'Person', prop: 'person_id'},
    { name: 'Notes', prop: 'notes'}
  ]

  const { Auth, datalists } = React.useContext(AuthContext);
  const [ params ] = useSearchParams();

  const [rows, setRows] = useState<ExpensesFormType[]>([])
  const [sortedBy, setSortedBy] = useState<{ column: keyof ExpensesFormType; ascending: boolean }>({ column: 'date', ascending: true })
  const [total, setTotal] = useState('');
  const [categories, setCategories] = useState<CombinedCategory[]>([])
  const [persons, setPersons] = useState<DataListStateType[]>([])

  // Component scrolls into view on mount
  const myRef = createRef<HTMLTableElement>();
  const executeScroll = () => {
    if (myRef.current) {
      myRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  async function deleteEntry(id: number) {
    await API.deleteExpenses(Auth.token, id);
    let newRows = rows.filter(
      (entry) => entry.id !== id
    );
    setRows(newRows);
  }

  // Helper function for sortBy
  function arraysEqual(a: ExpensesFormType[], b: ExpensesFormType[]): boolean {
    for (let i = 0; i < a.length; ++i) {
      if (a[i] !== b[i]) return false;
    }
    return true;
  }

  // Function to sort table by
  function sortBy(key: keyof ExpensesFormType) {
    let entries = [...rows];
    let sortedEntries = entries.sort((a, b) => (a[key]! >= b[key]! ? 1 : -1)); // "!" tells Typescript "I promise this won't be null or undefined"
    // If the array is already sorted ascending, sort it descending
    if (arraysEqual(sortedEntries, rows)) {
      sortedEntries = entries.sort((a, b) => (a[key]! <= b[key]! ? 1 : -1));
      setSortedBy({ column: key, ascending: false });
    } else {
      setSortedBy({ column: key, ascending: true });
    }
    setRows(sortedEntries);
  }

  useEffect(() => {
    const year = params.get("year");
    const month = params.get("month");
    const search = params.get("search")
    if (window.location.pathname.includes("search")) {
      API.search(Auth.token, search).then(setRows).then(executeScroll);
      return;
    }
    if (!year || !month) return;
    API.expenses(Auth.token, year, month).then(setRows).then(executeScroll);
  }, [window.location.search, window.location.pathname])

  useEffect(() => {
    API.categories(Auth.token).then((cats) => setCategories(cats));
    API.persons(Auth.token).then((persons) => setPersons(persons));
  }, []);

  useEffect(() => {
    if (!rows.length) return;
    let total = Number(
      rows
        .map((data) => data.amount)
        .reduce((agg, curr) => agg! + Number(curr))
    ).toFixed(2);
    setTotal(total);
  }, [rows]);

  if (!rows.length) return (
      <Typography variant="h2" component="h3" style={{ textAlign: "center" }}>No Data</Typography>
  );

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} ref={myRef}>
        <TableHead>
          <TableRow>
            {columns.map((column) => {
              return (
                <StyledTableCell
                  key={column.prop}
                  onClick={() => sortBy(column.prop)}
                  style={{ cursor: 'pointer' }}
                >
                  {column.name}
                  {sortedBy.column === column.prop ? (
                    sortedBy.ascending ? (
                      <ArrowDropDown style={{ marginBottom: '-7px' }} />
                    ) : (
                      <ArrowDropUp style={{ marginBottom: '-7px' }} />
                    )
                  ) : null}
                </StyledTableCell>
              );
            })}
            <StyledTableCell>
              <span>Delete</span>
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody className="tableBody">
          {rows.map((entry, i: number) => (
            <ExpenseInputRow
              entry={entry}
              i={i}
              key={i}
              categories={categories}
              persons={persons}
              dataLists={datalists}
              deleteEntry={deleteEntry}
              />
          ))}
          <StyledTableRow>
            <StyledTableCell style={{padding: '10px' }}>Total</StyledTableCell>
            <StyledTableCell>---------</StyledTableCell>
            <StyledTableCell>{'$' + total}</StyledTableCell>
            {columns.map((_, i) =>
              i < columns.length - 1 ? (
                <StyledTableCell key={i}></StyledTableCell>
              ) : null
            )}
          </StyledTableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
