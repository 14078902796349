import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import API from '../utils/API';
import { saveRecord } from '../utils/db';
import { AuthContext } from '../App';
import type {
  ExpensesFormType,
  CombinedCategory,
  DataListStateType,
} from '../interfaces/Interfaces';
import {
  Button,
  TextField,
  InputAdornment,
  Typography,
  DialogContent,
  InputLabel,
  FormControl,
} from '@material-ui/core';
import { CustomDatePickerComponent } from './CustomDatePickerComponent';
import { SelectComponent } from './SelectComponent';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';

type ReactInputEvent = React.ChangeEvent<{ name?: string; value: unknown }>;


export default function AddRecordsForm(props: {
  classes: { root: string; formControl: string };
  handleClose: Function;
  setLoading: Function;
  vendors: DataListStateType[];
  initialData: ExpensesFormType;
  initialDataSetter: Dispatch<SetStateAction<ExpensesFormType>>
}) {
  const { Auth, setAuth, setAlertState } = React.useContext(AuthContext);

  const initialFormState = {
    date: new Date(Date.now()),
    amount: NaN,
    person: '',
    person_id: NaN, 
    broad_category_id: NaN,
    narrow_category_id: NaN,
    broad_category: '',
    narrow_category: '',
    vendor: '',
    notes: '',
  };

  // Form control state
  const [formState, setFormState] =
    useState<ExpensesFormType>(initialFormState);

  const [categories, setCategories] = useState<CombinedCategory[]>([]);
  const [persons, setPersons] = useState<DataListStateType[]>([]);
  const [currentCategory, setCurrentCategory] = useState<CombinedCategory>({
    name: "",
    id: NaN,
    narrowCategories: []
  });

  useEffect(() => {
    loadData()
  }, []);

  useEffect(() => {
    if (!categories.length) return;
    if (!props.initialData) return;
    setFormState({ ...formState, ...props.initialData })
    if (props.initialData.broad_category_id) {
      updateCurrentCategory(props.initialData.broad_category_id)
    }
    props.initialDataSetter(null)
  }, [categories])

  async function loadData() {
    let cats: CombinedCategory[] = [];
    let persons: DataListStateType[] = [];
    try {
      [cats, persons] = await Promise.all([API.categories(Auth.token), API.persons(Auth.token)])
    } catch (err: any) {
      console.log(err);
      console.log("Trying again...");
      [cats, persons] = await Promise.all([API.categories(Auth.token), API.persons(Auth.token)])
    } finally {
      setCategories(cats)
      setPersons(persons)
    }
  }

  function handleFormChange(
    event: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | { name?: string; value: unknown }
    >
  ): void {
    let name = event.target.name as keyof typeof formState;
    setFormState({ ...formState, [name]: event.target.value });
  }

  function handleDateChange(date: Date | null) {
    setFormState({ ...formState, date: date });
  }

  async function handleFormSubmit(event: React.SyntheticEvent): Promise<any> {
    event.preventDefault();
    if (
      !formState.date ||
      !formState.amount ||
      !formState.broad_category_id ||
      !formState.vendor
    ) {
      setAlertState({
        severity: 'error',
        message: 'Please fill out all fields',
        open: true,
      });
      return;
    }
    let formStateConvertedDate: any = { ...formState };
    formStateConvertedDate.date =
      formStateConvertedDate.date?.toLocaleDateString('en-US');
    try {
      props.setLoading(true);
      await API.postExpenses(Auth.token, formStateConvertedDate);
      props.setLoading(false);
      setAlertState({
        severity: 'success',
        message: 'Record Saved!',
        open: true,
      });
    } catch (err: any) {
      props.setLoading(false);
      if (err.message === 'Error! 500') {
        setAlertState({
          severity: 'error',
          message: 'Server Error! Contact Eli',
          open: true,
        });
        return;
      } else {
        saveRecord('expenses', formStateConvertedDate);
        if (err.message === 'Unauthorized') {
          setAuth({ type: 'LOGOUT' });
        }
        setAlertState({
          severity: 'warning',
          message: 'Record Saved Locally',
          open: true,
        });
      }
    } finally {
      setFormState(initialFormState);
    }
  }

  function updateCurrentCategory(id: any): void {
    let category = categories.filter((i) => i.id === id)[0];
    setCurrentCategory(category);
  }

  const filter = createFilterOptions<string>();
  const labelId = "vendorComponentId"

  return (
    <DialogContent>
      <Typography variant="h5" component="h5" className={props.classes.root}>
        Log Expense
      </Typography>
      <form className={props.classes.root} onSubmit={handleFormSubmit}>
        <CustomDatePickerComponent
          value={formState.date}
          handleChange={handleDateChange}
        />
        <FormControl className={props.classes.formControl}>
          <InputLabel htmlFor={labelId} shrink={true}>Vendor</InputLabel>
          <Autocomplete
          id={labelId}
          disableClearable={true}
          value={formState.vendor }
          onChange={(_, value) => {
            handleFormChange({target: { name: 'vendor', value }} as unknown as ReactInputEvent)
          }}
          style={{ marginTop: '16px' }}
          options={props.vendors.filter(v => !!v.name).map(v => v.name)}
          getOptionLabel={v => v || ''}
          getOptionSelected={(o, v) => o === v}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);
            
            const { inputValue } = params;
            // Suggest the creation of a new value
            const isExisting = options.some((option) => inputValue === option);
            if (inputValue !== '' && !isExisting) {
              filtered.push(inputValue);
            }
            
            return filtered;
          }}
          renderInput={(params) => <TextField {...params} />}
          />
        </FormControl>
        <TextField
          className={props.classes.formControl}
          onChange={handleFormChange}
          value={formState.amount}
          label="Amount"
          name="amount"
          type="number"
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          inputProps={{ step: '0.01' }}
        />
        <SelectComponent
        classes={props.classes.formControl}
        handleFormChange={(e) => {
          handleFormChange(e);
          updateCurrentCategory(e.target.value);
        }}
        value={formState.broad_category_id}
        options={categories}
        label={"Broad Category"}
        fieldName="broad_category_id"
        />
      {formState.broad_category_id &&
      currentCategory.narrowCategories?.length ? (
        <SelectComponent
          classes={props.classes.formControl}
          handleFormChange={handleFormChange}
          value={formState.narrow_category_id}
          options={currentCategory.narrowCategories}
          label={"Narrow Category"}
          fieldName="narrow_category_id"
        />
      ) : null}
      {currentCategory.hasPerson ? (
        <SelectComponent
          classes={props.classes.formControl}
          handleFormChange={handleFormChange}
          value={formState.person_id}
          options={persons}
          label={"Person"}
          fieldName="person_id"
        />
      ) : null}
        <TextField
          className={props.classes.formControl}
          onChange={handleFormChange}
          value={formState.notes}
          label="Notes"
          name="notes"
          type="string"
          InputLabelProps={{ shrink: true }}
        />
        <Button type="submit" variant="contained" color="primary">
          Submit
        </Button>
        <Button
          type="button"
          variant="contained"
          color="secondary"
          onClick={() => {
            setFormState(initialFormState);
            props.handleClose();
          }}
        >
          Close
        </Button>
      </form>
    </DialogContent>
  );
}
