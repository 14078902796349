import DateFnsUtils from '@date-io/date-fns';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import React from 'react';

export function CustomDatePickerComponent({
  value,
  handleChange,
  tableMode,
}: {
  value: Date | null;
  handleChange: (e: Date | null) => void;
  tableMode?: boolean;
}) {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        style={tableMode ? { margin: "0 0 0 3px" } : {}}
        disableToolbar
        autoOk={true}
        fullWidth={true}
        variant="inline"
        format="MM/dd/yyyy"
        margin="normal"
        id="date-picker-inline"
        name="date"
        label={tableMode ? undefined : "Date"}
        value={value}
        onChange={handleChange}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
      />
    </MuiPickersUtilsProvider>
  );
}
