export interface FormStateType {
  year: number;
  month: number;
  search: string;
}

export type InputName =
  | 'Person'
  | 'Source'
  | 'Broad_category'
  | 'Narrow_category'
  | 'Vendor';

export interface TableDataEntry {
  amount: number;
  date?: string;
  source?: string;
  person?: string;
  id?: number;
  source_id?: number;
  earner_id?: number;
  vendor?: string;
  broad_category?: string;
  narrow_category?: string;
  notes?: string;
  entry_id?: number;
}

export interface ExpensesFormType {
  id?: number;
  date: Date | null;
  amount: number;
  person: string;
  broad_category: string;
  narrow_category: string;
  person_id: number;
  broad_category_id: number;
  narrow_category_id: number;
  vendor: string;
  vendor_id?: number;
  notes: string;
}

export interface IncomeFormType {
  id?: number;
  date: Date | null;
  amount: number;
  person_id: number;
  source: string;
  source_id?: number;
}

export interface RecurringExpense {
  id?: number;
  day: number;
  vendor: string;
  amount: number;
  broad_category_id: number;
  narrow_category_id: number | null;
  person_id: number | null;
  notes: string;
}

export interface PersonEarner {
  id?: number;
  ordinal: number;
  name: string;
  isPerson: boolean;
  isEarner: boolean;
  archived: boolean;
}

export interface DataListStateType {
  id: number;
  name: string;
}

export interface AllDataListsType {
  source: DataListStateType[];
  person_earner: DataListStateType[];
  narrow_category: DataListStateType[];
  broad_category: DataListStateType[];
  vendor: DataListStateType[];
}

export interface CombinedCategory extends DataListStateType {
  narrowCategories?: DataListStateType[];
  hasPerson?: boolean;
}

export interface BroadCategory {
  id?: number;
  name: string;
  archived: boolean;
  hasPerson: boolean;
  ordinal: number;
}

export interface NarrowCategory {
  id?: number;
  name: string;
  archived: boolean;
  hasPerson: boolean;
  ordinal: number;
  broad_category_id: number;
}

export type TableType = Partial<ExpensesFormType & IncomeFormType>;

export interface AlertStateType {
  severity: 'success' | 'info' | 'warning' | 'error' | undefined;
  message: string;
  open: boolean;
}

export interface Auth {
  loggedIn: boolean;
  user: string;
  token: string;
}

export interface ContextState {
  Auth: Auth;
  setAuth: React.Dispatch<{
    type: string;
    payload?: { user: string; token: string } | undefined;
  }>;
  setAlertState: React.Dispatch<React.SetStateAction<AlertStateType>>;
  datalists: AllDataListsType,
  setDatalists: React.Dispatch<AllDataListsType>,
}

export enum CopilotAction {
  logExpense = "logExpense",
  answer = "answer",
}

export interface CopilotResponse {
  action: CopilotAction;
  data: string;
  message: string;
}
