import React, { useState, useEffect } from 'react';
import AddExpensesForm from '../components/AddExpensesForm';
import AddIncomeForm from '../components/AddIncomeForm';
import API from '../utils/API';
import { createStyles, makeStyles, styled, Theme, withStyles } from '@material-ui/core/styles';
import { AuthContext } from '../App';
import type {
  AllDataListsType,
  FormStateType,
  ExpensesFormType,
} from '../interfaces/Interfaces';
import {
  Backdrop,
  CircularProgress,
  Dialog,
  Box,
  Typography,
  Tabs,
  Tab,
  DialogContent,
  Drawer,
  IconButton,
  Fab,
} from '@material-ui/core';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import AddIcon from '@material-ui/icons/Add';
import FaceIcon from '@material-ui/icons/Face';
import Form from '../components/Form';
import MyAppBar from '../components/MyAppBar';
import { checkDatabase } from '../utils/db';
import { Link, Outlet, useSearchParams } from 'react-router-dom';
import Copilot from '../components/Copilot';
import { ArrowForwardIos } from '@material-ui/icons';


const drawerWidth = 240;

// Create classes to use for styling
export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: '12em',
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    root: {
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      [theme.breakpoints.up('md')]: {
        flexWrap: 'noWrap',
      },
      '& > *': {
        margin: theme.spacing(1),
        [theme.breakpoints.down('xs')]: {
          width: '100%',
        },
      },
    },
    wallchart: {
      width: '100%',
    },
    logoutBtn: {
      float: 'right',
      margin: '1em',
    },
    downloadBtn: {
      float: 'left',
      margin: '1em',
    },
    backdrop: {
      zIndex: 1301, // To be in front of Dialog at 1300
      color: '#fff',
    },
    copilotButton: {
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(10),
    },
    speedDial: {
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    dialog: {
      width: '100%',
    },
    datePicker: {
      [theme.breakpoints.down('sm')]: {
        marginLeft: '-10px',
      },
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      '& .MuiDrawer-paper': {
        width: drawerWidth,
        marginTop: "64px",
        paddingBottom: "64px",
        display: "flex",
      }
    },
    mobileTitle: {
      display: 'none',
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
      },
      justifyContent: 'center',
    },
    sectionDesktop: {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    sectionMobile: {
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
  })
);

const TooltipAction = withStyles({
  staticTooltipLabel: {
      backgroundColor: "green"
  }
})(SpeedDialAction)

function Home() {

  const classes = useStyles();
  const { Auth, setAlertState, datalists, setDatalists } = React.useContext(AuthContext);
  const [params, setParams] = useSearchParams();

  // Form control state
  const [formState, setFormState] = useState<FormStateType>({
    year: Number(params.get("year")) || new Date(Date.now()).getUTCFullYear(),
    month: Number(params.get("month")) || new Date(Date.now()).getUTCMonth() + 1,
    search: '',
  });

  // Loading Backdrop display state
  const [loading, setLoading] = useState(false);

  // Form control
  function handleFormChange(
    event:
      | React.ChangeEvent<
          | HTMLInputElement
          | HTMLTextAreaElement
          | { name?: string; value: unknown }
        >
      | React.ChangeEvent<HTMLSelectElement>
  ): void {
    let name = event.target.name as keyof typeof formState;
    setFormState({ ...formState, [name]: event.target.value });
  }

  function handleFormSubmit(event: React.SyntheticEvent) {
    event.preventDefault();
    setParams({ year: formState.year.toString(), month: formState.month.toString(), search: formState.search })
  }

  // SpeedDial controls
  const [speedDialOpen, setSpeedDialOpen] = React.useState(false);

  const actions = [
    {
      icon: <AddIcon />,
      name: 'Expenses',
      action: handleExpensesOpen,
    },
    {
      icon: <AddIcon />,
      name: 'Income',
      action: handleIncomeOpen,
    }
  ];

  const handleSpeedDialClose = () => {
    setSpeedDialOpen(false);
  };

  const handleOpen = () => {
    setSpeedDialOpen(true);
  };

  function handleExpensesOpen(): void {
    setAddExpensesOpen(true);
    setSpeedDialOpen(false);
  }

  function handleIncomeOpen(): void {
    setAddIncomeOpen(true);
    setSpeedDialOpen(false);
  }

  function handleCopilotOpen(): void {
    setCopilotOpen(true);
    setSpeedDialOpen(false);
  }

  function handleCopilotClose(): void {
    setCopilotOpen(false);
  }

  // Controls for Dialogs
  const [addExpensesOpen, setAddExpensesOpen] = useState(false);
  const [addIncomeOpen, setAddIncomeOpen] = useState(false);
  const [copilotOpen, setCopilotOpen] = useState(false);

  function handleClose() {
    setAddExpensesOpen(false);
    setAddIncomeOpen(false);
  }

  useEffect(() => {
    async function getDataLists(): Promise<void> {
      let datalists: AllDataListsType
      try {
        datalists = await API.dataList(Auth.token);
      } catch (err) {
        console.log(err)
        console.log("Trying again...")
        try {
          datalists = await API.dataList(Auth.token);
        } catch (err) {
          console.log(err)
          console.log("Failed again...")
        }
      }
      setDatalists(datalists);
    }
    getDataLists();
  }, []);

  useEffect(() => {
    async function checkAndDisplaySuccess() {
      let result = await checkDatabase();
      setAlertState(result);
    }
    // listen for app coming back online
    window.addEventListener('online', checkAndDisplaySuccess);
    return () => window.removeEventListener('online', checkAndDisplaySuccess);
  }, []);

  const [value, setValue] = React.useState(0);

  const handleTabChange = (_: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const [ expensesInputData, setExpensesInputData ] = React.useState<ExpensesFormType>()
  function fillExpensesData(data: ExpensesFormType) {
    setExpensesInputData(data);
    setAddExpensesOpen(true);
  }

  const mapPathToTab = {
    "expenses": 0,
    "income": 1,
    "pivot": 2,
    "search": 3,
  }

  useEffect(() => {
    const pathname = window.location.pathname;
    const segments = pathname.split("/")
    const lastSegment = segments[segments.length-1]
    handleTabChange(null, mapPathToTab[lastSegment])
  },[])

  useEffect(() => {
    const year = params.get("year")
    const month = params.get("month")
    if (!year && !month) {
      setParams({ year: formState.year.toString(), month: formState.month.toString() })
    }
  }, [window.location.pathname])

  return (
    <>
      <MyAppBar showDownload={true} linkLocation='edit'/>
      <Box >
        <Box component="header">
          <div>
            <Typography className={classes.mobileTitle} variant="h6" noWrap>
              Finances
            </Typography>
          </div>
          <img src="/wallchart" alt="Wall Chart" className={classes.wallchart} />
        </Box>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            centered
            style={{ marginBottom: "7px" }}
            value={value}
            onChange={handleTabChange}
            aria-label="navigation"
            indicatorColor="primary"
            textColor="primary">
            <Tab label="Expenses" to={{ pathname: "/home/expenses", search: window.location.search }} component={Link}/>
            <Tab label="Income" to={{ pathname: "/home/income", search: window.location.search }} component={Link}/>
            <Tab label="Pivot" to={{ pathname: "/home/pivot", search: window.location.search }} component={Link}/>
            <Tab label="Search" to={{ pathname: "/home/search", search: window.location.search }} component={Link}/>
          </Tabs>
          <Form
            classes={classes}
            handleFormSubmit={handleFormSubmit}
            handleFormChange={handleFormChange}
            formState={formState}
          />
        </Box>
        <div className="body">
          <Outlet />
        </div>
        <Dialog onClose={handleClose} open={addExpensesOpen} maxWidth="xl">
          <AddExpensesForm
            classes={classes}
            handleClose={handleClose}
            setLoading={setLoading}
            vendors={datalists?.vendor}
            initialData={expensesInputData}
            initialDataSetter={setExpensesInputData}
          />
        </Dialog>
        <Dialog onClose={handleClose} open={addIncomeOpen} maxWidth="xl">
          <AddIncomeForm
            classes={classes}
            handleClose={handleClose}
            setLoading={setLoading}
            sources={datalists?.source}
          />
        </Dialog>
        <Dialog onClose={() => setCopilotOpen(false)} open={copilotOpen} maxWidth="xl" className={classes.sectionMobile}>
          <DialogContent>
            <Copilot
              setLoading={setLoading}
              setOpen={setCopilotOpen}
              classes={classes}
              fillExpensesData={fillExpensesData}
            />
          </DialogContent>
        </Dialog>
        <Fab
          className={classes.copilotButton}
          onClick={handleCopilotOpen}
          color="primary"
          >
          <FaceIcon/>
        </Fab>
        <SpeedDial
          ariaLabel="SpeedDial"
          className={classes.speedDial}
          // hidden={hidden}
          icon={<SpeedDialIcon />}
          onClose={handleSpeedDialClose}
          onOpen={handleOpen}
          open={speedDialOpen}
          onMouseLeave={() => {}}
        >
          {actions.map((action) => (
            <TooltipAction
              FabProps={{style: {backgroundColor: "green"}}}
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              tooltipOpen
              onClick={action.action}
            />
          ))}
        </SpeedDial>
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress disableShrink color="inherit" />
        </Backdrop>
      </Box>
      <Drawer
          className={`${classes.sectionDesktop} ${classes.drawer}`}
          anchor="right"
          open={copilotOpen}
        >
        <IconButton onClick={handleCopilotClose} style={{ width: "48px" }}>
          <ArrowForwardIos />
        </IconButton>
        <Copilot
              setLoading={setLoading}
              setOpen={setCopilotOpen}
              classes={classes}
              fillExpensesData={fillExpensesData}
            />
        </Drawer>
    </>
  );
}

export default Home;
