import {
  Button,
  Checkbox,
  IconButton,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

import React, { useEffect, useState } from 'react';
import API from '../utils/API';
import { AuthContext } from '../App';
import {
  BroadCategory,
  NarrowCategory,
  PersonEarner,
  RecurringExpense,
} from '../interfaces/Interfaces';
import { AddCircle } from '@material-ui/icons';
import MyAppBar from '../components/MyAppBar';

export function Edit() {
  const { Auth, setAlertState } = React.useContext(AuthContext);
  const [broadCategories, setBroadCategories] = useState<BroadCategory[]>([]);
  const [narrowCategories, setNarrowCategories] = useState<NarrowCategory[]>(
    []
  );
  const [persons, setPersons] = useState<PersonEarner[]>([]);
  const [recurringExpenses, setRecurringExpenses] = useState<
    RecurringExpense[]
  >([]);

  const [showRecurringExpenses, setShowRecurringExpenses] =
    useState<boolean>(false);
  const [showBroadCategories, setShowBroadCategories] =
    useState<boolean>(false);
  const [showNarrowCategories, setShowNarrowCategories] =
    useState<boolean>(false);
  const [showPersons, setShowPersons] = useState<boolean>(false);
  const [showArchivedBroadCategories, setShowArchivedBroadCategories] =
    useState<boolean>(false);
  const [showArchivedNarrowCategories, setShowArchivedNarrowCategories] =
    useState<boolean>(false);
  const [showArchivedPersons, setShowArchivedPersons] =
    useState<boolean>(false);

  useEffect(() => {
    API.broadCategories(Auth.token).then((bCats) => setBroadCategories(bCats.sort((a, b) => a.id - b.id)));
    API.narrowCategories(Auth.token).then((nCats) =>
      setNarrowCategories(nCats.sort((a, b) => a.broad_category_id - b.broad_category_id))
    );
    API.personEarners(Auth.token).then((persons) => setPersons(persons));
    API.getRecurringExpenses(Auth.token).then((recurringExpenses) =>
      setRecurringExpenses(recurringExpenses)
    );
  }, []);

  async function addRecurringExpense() {
    const newRecurringExpense: RecurringExpense = {
      day: 1,
      vendor: '',
      amount: 0,
      broad_category_id: 0,
      narrow_category_id: null,
      person_id: null,
      notes: '',
    };
    try {
      await API.addRecurringExpense(Auth.token, newRecurringExpense);
      const updated = await API.getRecurringExpenses(Auth.token);
      setRecurringExpenses(updated);
    } catch (err: any) {
      setAlertState({
        severity: 'error',
        message: err.message,
        open: true,
      });
      console.error(err);
    }
  }

  async function addBroadCategory() {
    const newBroadCategory: BroadCategory = {
      name: '',
      ordinal: broadCategories.length + 1,
      hasPerson: false,
      archived: false,
    };
    try {
      await API.addBroadCategory(Auth.token, newBroadCategory);
      const updated = await API.broadCategories(Auth.token);
      setBroadCategories(updated);
    } catch (err: any) {
      setAlertState({
        severity: 'error',
        message: err.message,
        open: true,
      });
      console.error(err);
    }
  }

  async function addNarrowCategory() {
    const newNarrowCategory: NarrowCategory = {
      name: '',
      broad_category_id: 0,
      ordinal: narrowCategories.length + 1,
      hasPerson: false,
      archived: false,
    };
    try {
      await API.addNarrowCategory(Auth.token, newNarrowCategory);
      const updated = await API.narrowCategories(Auth.token);
      setNarrowCategories(updated);
    } catch (err: any) {
      setAlertState({
        severity: 'error',
        message: err.message,
        open: true,
      });
      console.error(err);
    }
  }

  async function addPersonEarner() {
    const newPersonEarner: PersonEarner = {
      name: '',
      ordinal: persons.length + 1,
      isPerson: false,
      isEarner: false,
      archived: false,
    };
    try {
      await API.addPersonEarner(Auth.token, newPersonEarner);
      const updated = await API.personEarners(Auth.token);
      setPersons(updated);
    } catch (err: any) {
      setAlertState({
        severity: 'error',
        message: err.message,
        open: true,
      });
      console.error(err);
    }
  }

  async function deleteRecurringExpense(id: number) {
    try {
      await API.deleteRecurringExpense(Auth.token, id);
      const updated = await API.getRecurringExpenses(Auth.token);
      setRecurringExpenses(updated);
    } catch (err: any) {
      setAlertState({
        severity: 'error',
        message: err.message,
        open: true,
      });
      console.error(err);
    }
  }

  async function deleteBroadCategory(id: number) {
    try {
      await API.deleteBroadCategory(Auth.token, id);
      const updated = await API.broadCategories(Auth.token);
      setBroadCategories(updated);
    } catch (err: any) {
      setAlertState({
        severity: 'error',
        message: err.message,
        open: true,
      });
      console.error(err);
    }
  }

  async function deleteNarrowCategory(id: number) {
    try {
      await API.deleteNarrowCategory(Auth.token, id);
      const updated = await API.narrowCategories(Auth.token);
      setNarrowCategories(updated);
    } catch (err: any) {
      setAlertState({
        severity: 'error',
        message: err.message,
        open: true,
      });
      console.error(err);
    }
  }

  async function deletePersonEarner(id: number) {
    try {
      await API.deletePersonEarner(Auth.token, id);
      const updated = await API.personEarners(Auth.token);
      setPersons(updated);
    } catch (err: any) {
      setAlertState({
        severity: 'error',
        message: err.message,
        open: true,
      });
      console.error(err);
    }
  }

  function handleRecurringExpenseChange(
    event: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | { name?: string; value: unknown }
    >,
    i: number
  ): void {
    let { name, value } = event.target;
    let editedRecord = { ...recurringExpenses[i] };
    editedRecord = { ...editedRecord, [name as string]: value };
    const newRecurringExpenses = [...recurringExpenses];
    newRecurringExpenses[i] = editedRecord;
    setRecurringExpenses(newRecurringExpenses);
    API.UpdateRecurringExpense(Auth.token, editedRecord);
  }

  function handleBroadCategoryChange(
    event: React.ChangeEvent<HTMLInputElement>,
    i: number
  ): void {
    let { name, value } = event.target;
    let editedRecord = { ...broadCategories[i] };
    if (event.target.type === 'checkbox') {
      editedRecord = { ...editedRecord, [name]: event.target.checked };
      value = 'banana';
    } else {
      editedRecord = { ...editedRecord, [name]: value };
    }
    const newBroadCategories = [...broadCategories];
    newBroadCategories[i] = editedRecord;
    setBroadCategories(newBroadCategories);
    API.UpdateBroadCategory(Auth.token, editedRecord);
  }

  function handleNarrowCategoryChange(
    event: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | { name?: string; value: unknown }
    >,
    i: number
  ): void {
    let { name, value } = event.target;
    let editedRecord = { ...narrowCategories[i] };

    editedRecord = { ...editedRecord, [name as string]: value };

    const newNarrowCategories = [...narrowCategories];
    newNarrowCategories[i] = editedRecord;
    setNarrowCategories(newNarrowCategories);
    API.UpdateNarrowCategory(Auth.token, editedRecord);
  }

  function handleNarrowCategoryCheckboxChange(
    event: React.ChangeEvent<HTMLInputElement>,
    i: number
  ): void {
    let { name, checked } = event.target;
    let editedRecord = { ...narrowCategories[i] };
    editedRecord = { ...editedRecord, [name]: checked };
    const newNarrowCategories = [...narrowCategories];
    newNarrowCategories[i] = editedRecord;
    setNarrowCategories(newNarrowCategories);
    API.UpdateNarrowCategory(Auth.token, editedRecord);
  }

  function handlePersonEarnerChange(
    event: React.ChangeEvent<HTMLInputElement>,
    i: number
  ): void {
    let { name, value, checked } = event.target;
    let editedRecord = { ...persons[i] };
    if (event.target.type === 'checkbox') {
      editedRecord = { ...editedRecord, [name]: checked };
    } else {
      editedRecord = { ...editedRecord, [name]: value };
    }
    const newPersons = [...persons];
    newPersons[i] = editedRecord;
    setPersons(newPersons);
    API.UpdatePersonEarners(Auth.token, editedRecord);
  }

  function toggleRecurringExpenses() {
    setShowRecurringExpenses(!showRecurringExpenses);
  }

  function toggleBroadCategories() {
    setShowBroadCategories(!showBroadCategories);
  }

  function toggleNarrowCategories() {
    setShowNarrowCategories(!showNarrowCategories);
  }

  function togglePersons() {
    setShowPersons(!showPersons);
  }

  function toggleShowArchivedBroadCategories() {
    setShowArchivedBroadCategories(!showArchivedBroadCategories);
  }

  function toggleShowArchivedNarrowCategories() {
    setShowArchivedNarrowCategories(!showArchivedNarrowCategories);
  }

  function toggleShowArchivedPersons() {
    setShowArchivedPersons(!showArchivedPersons);
  }

  return (
    <>
    <MyAppBar showDownload={false} linkLocation='home'/>
      <Typography variant="h1" component="h1">
        Edit
      </Typography>
      <Typography
        variant="h2"
        component="h2"
        onClick={toggleRecurringExpenses}
        style={{ cursor: 'pointer' }}
      >
        Recurring Expenses
      </Typography>
      {showRecurringExpenses && (
        <>
          <Table>
            <TableHead>
              <TableCell>Day</TableCell>
              <TableCell>Vendor</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Broad Category</TableCell>
              <TableCell>Narrow Category</TableCell>
              <TableCell>Person</TableCell>
              <TableCell>Notes</TableCell>
              <TableCell>Delete</TableCell>
            </TableHead>
            <TableBody>
              {recurringExpenses.map((recEx, i) => (
                <TableRow>
                  <TableCell>
                    <TextField
                      value={recEx.day || ''}
                      name="day"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleRecurringExpenseChange(e, i)
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      value={recEx.vendor || ''}
                      name="vendor"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleRecurringExpenseChange(e, i)
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      value={recEx.amount || ''}
                      name="amount"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleRecurringExpenseChange(e, i)
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <Select
                      value={recEx.broad_category_id || ''}
                      name="broad_category_id"
                      onChange={(
                        e: React.ChangeEvent<{
                          name?: string;
                          value: unknown;
                        }>
                      ) => handleRecurringExpenseChange(e, i)}
                    >
                      {broadCategories
                        .filter((bCat) => !bCat.archived)
                        .map((bCat) => (
                          <MenuItem value={bCat.id}>{bCat.name}</MenuItem>
                        ))}
                    </Select>
                  </TableCell>
                  <TableCell>
                    <Select
                      value={recEx.narrow_category_id || ''}
                      name="narrow_category_id"
                      onChange={(
                        e: React.ChangeEvent<{
                          name?: string;
                          value: unknown;
                        }>
                      ) => handleRecurringExpenseChange(e, i)}
                    >
                      {narrowCategories
                        .filter((nCat) => !nCat.archived)
                        .filter((nCat) => nCat.broad_category_id === recEx.broad_category_id)
                        .map((nCat) => (
                          <MenuItem value={nCat.id}>{nCat.name}</MenuItem>
                        ))}
                    </Select>
                  </TableCell>
                  <TableCell>
                    <Select
                      value={recEx.person_id || ''}
                      name="person_id"
                      onChange={(
                        e: React.ChangeEvent<{
                          name?: string;
                          value: unknown;
                        }>
                      ) => handleRecurringExpenseChange(e, i)}
                    >
                      {persons.map((person) => (
                        <MenuItem value={person.id}>{person.name}</MenuItem>
                      ))}
                    </Select>
                  </TableCell>
                  <TableCell>
                    <TextField
                      value={recEx.notes || '' || ''}
                      name="notes"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleRecurringExpenseChange(e, i)
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <IconButton
                      aria-label="delete"
                      color="secondary"
                      onClick={() => {
                        deleteRecurringExpense(recEx.id!);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <div
            style={{
              width: '100vw',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <IconButton color="primary" onClick={addRecurringExpense}>
              <AddCircle />
            </IconButton>
          </div>
        </>
      )}
      <Typography
        variant="h2"
        component="h2"
        onClick={toggleBroadCategories}
        style={{ cursor: 'pointer' }}
      >
        Broad Categories
      </Typography>
      {showBroadCategories && (
        <>
          <Button onClick={toggleShowArchivedBroadCategories}>{`${
            showArchivedBroadCategories ? 'Hide' : 'Show'
          } Archived`}</Button>
          <Table>
            <TableHead>
              <TableCell>Ordinal</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Archived</TableCell>
              <TableCell>Has Person</TableCell>
              <TableCell>Delete</TableCell>
            </TableHead>
            <TableBody>
              {broadCategories
                .map(
                (bCat, i) =>
                showArchivedBroadCategories || !bCat.archived ? (
                    <TableRow>
                      <TableCell>
                        <TextField
                          value={bCat.ordinal || ''}
                          name="ordinal"
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleBroadCategoryChange(e, i)
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          value={bCat.name || ''}
                          name="name"
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleBroadCategoryChange(e, i)
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <Checkbox
                          checked={bCat.archived}
                          name="archived"
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleBroadCategoryChange(e, i)
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <Checkbox
                          checked={bCat.hasPerson}
                          name="hasPerson"
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleBroadCategoryChange(e, i)
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <IconButton
                          aria-label="delete"
                          color="secondary"
                          onClick={() => {
                            deleteBroadCategory(bCat.id!);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ) : null
              )}
            </TableBody>
          </Table>
          <div
            style={{
              width: '100vw',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <IconButton color="primary" onClick={addBroadCategory}>
              <AddCircle />
            </IconButton>
          </div>
        </>
      )}
      <Typography
        variant="h2"
        component="h2"
        onClick={toggleNarrowCategories}
        style={{ cursor: 'pointer' }}
      >
        Narrow Categories
      </Typography>
      {showNarrowCategories && (
        <>
          <Button onClick={toggleShowArchivedNarrowCategories}>{`${
            showArchivedNarrowCategories ? 'Hide' : 'Show'
          } Archived`}</Button>
          <Table>
            <TableHead>
              <TableCell>Ordinal</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Broad Category</TableCell>
              <TableCell>Archived</TableCell>
              <TableCell>Delete</TableCell>
            </TableHead>
            <TableBody>
              {narrowCategories
                .map(
                  (nCat, i) =>
                  showArchivedNarrowCategories || !nCat.archived ? (
                      <TableRow>
                        <TableCell>
                          <TextField
                            value={nCat.ordinal || ''}
                            name="ordinal"
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => handleNarrowCategoryChange(e, i)}
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            value={nCat.name || ''}
                            name="name"
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => handleNarrowCategoryChange(e, i)}
                          />
                        </TableCell>
                        <TableCell>
                          <Select
                            value={nCat.broad_category_id || ''}
                            name="broad_category_id"
                            onChange={(
                              e: React.ChangeEvent<{
                                name?: string;
                                value: unknown;
                              }>
                            ) => handleNarrowCategoryChange(e, i)}
                          >
                            {broadCategories.filter(bCat => !bCat.archived).map((bCat) => (
                              <MenuItem value={bCat.id}>{bCat.name}</MenuItem>
                            ))}
                          </Select>
                        </TableCell>
                        <TableCell>
                          <Checkbox
                            checked={nCat.archived}
                            name="archived"
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => handleNarrowCategoryCheckboxChange(e, i)}
                          />
                        </TableCell>
                        <TableCell>
                          <IconButton
                            aria-label="delete"
                            color="secondary"
                            onClick={() => {
                              deleteNarrowCategory(nCat.id!);
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ) : null
                )}
            </TableBody>
          </Table>
          <div
            style={{
              width: '100vw',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <IconButton color="primary" onClick={addNarrowCategory}>
              <AddCircle />
            </IconButton>
          </div>
        </>
      )}
      <Typography
        variant="h2"
        component="h2"
        onClick={togglePersons}
        style={{ cursor: 'pointer' }}
      >
        Persons
      </Typography>
      {showPersons && (
        <>
          <Button onClick={toggleShowArchivedPersons}>{`${
            showArchivedPersons ? 'Hide' : 'Show'
          } Archived`}</Button>
          <Table>
            <TableHead>
              <TableCell>Ordinal</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Is Person</TableCell>
              <TableCell>Is Earner</TableCell>
              <TableCell>Archived</TableCell>
              <TableCell>Delete</TableCell>
            </TableHead>
            <TableBody>
              {persons
                .map((person, i) => (
                  showArchivedPersons || !person.archived ? (
                  <TableRow>
                    <TableCell>
                      <TextField
                        value={person.ordinal || ''}
                        name="ordinal"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handlePersonEarnerChange(e, i)
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        value={person.name || ''}
                        name="name"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handlePersonEarnerChange(e, i)
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <Checkbox
                        checked={person.isPerson}
                        name="isPerson"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handlePersonEarnerChange(e, i)
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <Checkbox
                        checked={person.isEarner}
                        name="isEarner"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handlePersonEarnerChange(e, i)
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <Checkbox
                        checked={person.archived}
                        name="archived"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handlePersonEarnerChange(e, i)
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <IconButton
                        aria-label="delete"
                        color="secondary"
                        onClick={() => {
                          deletePersonEarner(person.id!);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ) : null))}
            </TableBody>
          </Table>
          <div
            style={{
              width: '100vw',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <IconButton color="primary" onClick={addPersonEarner}>
              <AddCircle />
            </IconButton>
          </div>
        </>
      )}
    </>
  );
}
