import { createStyles, TableCell, Theme, withStyles } from "@material-ui/core";


export const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    body: {
      fontSize: 14,
      padding: 0,
      maxWidth: '10ch',
      overflow: 'hidden',
    },
    head: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        padding: 10,
        fontSize: 16,
      },
  })
)(TableCell);