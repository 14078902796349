import React, { useState, useEffect, ChangeEvent } from 'react';
import {
  AllDataListsType,
  ExpensesFormType,
  DataListStateType,
  CombinedCategory,
} from '../interfaces/Interfaces';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  IconButton,
  TableRow,
  TextField,
  InputAdornment,
} from '@material-ui/core';
import { withStyles, createStyles, Theme } from '@material-ui/core/styles';
import { blueGrey } from '@material-ui/core/colors';
import { CustomDatePickerComponent } from './CustomDatePickerComponent';
import { useStyles } from '../pages/Home';
import { CategorySelectGroupComponent } from './CategorySelectGroupComponent';
import { StyledTableCell } from './StyledTableCell';
import API from '../utils/API';
import { AuthContext } from '../App';
import { Autocomplete } from '@material-ui/lab';

export const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.background.default,
      },
      '&:nth-of-type(even)': {
        backgroundColor: blueGrey[800],
      },
      '& input': {
        backgroundColor: 'inherit',
        paddingLeft: 0,
      },
    },
  })
)(TableRow);

export interface InputRow {
  entry: ExpensesFormType;
  categories: CombinedCategory[];
  persons: DataListStateType[];
  i: number;
  dataLists: AllDataListsType;
  deleteEntry: Function;
}

export default function ExpenseInputRow(props: InputRow) {
  const initialFormState: ExpensesFormType = {
    date: null,
    amount: NaN,
    person: '',
    broad_category: '',
    narrow_category: '',
    person_id: NaN,
    broad_category_id: NaN,
    narrow_category_id: NaN,
    vendor: '',
    notes: '',
  };
  const [state, setState] = useState<ExpensesFormType>(initialFormState);

  const { Auth, setAlertState } = React.useContext(AuthContext);

  const classes = useStyles();

  function handleInputRowChange(
    event: React.ChangeEvent<
      HTMLInputElement | { name?: string; value: unknown }
    >
  ): void {
    let name = event.target.name as keyof typeof state;
    setState({ ...state, [name]: event.target.value });
  }

  function handleDateChange(date: Date | null) {
    setState({ ...state, date: date });
  }

  function handleVendorChange(_: ChangeEvent<{}>, value: unknown) {
    if (!value) return;
    setState({ ...state, vendor: (value as DataListStateType).name, vendor_id: (value as DataListStateType).id })
  }

  async function saveChanges() {
    let updatedExpense: any = { ...state };
    updatedExpense.date = updatedExpense.date?.toLocaleDateString('en-US');
    try {
      await API.updateExpenses(Auth.token, updatedExpense);
      setAlertState({
        severity: 'success',
        message: 'Record updated!',
        open: true,
      });
    } catch (err) {
      console.log(err);
      setAlertState({
        severity: 'error',
        message: 'Error: Failed to save!',
        open: true,
      });
    }
  }

  useEffect(() => {
    setState(props.entry);
  }, [props.entry]);

  return (
    <StyledTableRow>
      <StyledTableCell>
        <CustomDatePickerComponent
          value={state.date}
          handleChange={date => {
            handleDateChange(date)
            setTimeout(() => saveChanges(), 0)
          }}
          tableMode={true}
        />
      </StyledTableCell>
      <StyledTableCell>
        { state.vendor && props.dataLists.vendor ? 
          <Autocomplete
            disableClearable={true}
            value={{ name: state.vendor, id: state.vendor_id }}
            onChange={handleVendorChange}
            onBlur={saveChanges}
            style={{ width: '80%', paddingLeft: '5px' }}
            options={props.dataLists.vendor.filter(v => !!v.name)}
            getOptionLabel={(v: { name: string }) => v.name || ''}
            getOptionSelected={(o: { id: string }, v: { id: string }) => o.id === v.id}
            renderInput={(params) => <TextField {...params} />}
          /> : null
          }
      </StyledTableCell>
      <StyledTableCell>
        <TextField
          name="amount"
          onBlur={saveChanges}
          value={state.amount || ''}
          onChange={handleInputRowChange}
          className="tableInput"
          inputProps={{
            list: 'amount',
            type: 'number',
            step: 0.01,
          }}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          style={{ width: '80%' }}
        />
      </StyledTableCell>
      <CategorySelectGroupComponent
        classes={classes.formControl}
        formState={state}
        handleFormChange={e => {
          handleInputRowChange(e)
          setTimeout(() => saveChanges(), 0)
        }}
        categories={props.categories}
        persons={props.persons}
        tableMode={true}
        />
      <StyledTableCell>
        <TextField
          onChange={handleInputRowChange}
          onBlur={saveChanges}
          value={state.notes}
          name="notes"
          type="string"
          style={{ width: '80%' }}
          />
      </StyledTableCell>
      <StyledTableCell>
        <IconButton
          aria-label="delete"
          color="secondary"
          onClick={() => props.deleteEntry(state.id)}
        >
          <DeleteIcon />
        </IconButton>
      </StyledTableCell>
    </StyledTableRow>
  );
}
